//node_modules
import { Flex, Grid, Image, Layout } from "antd";
import { useMotionValueEvent, useScroll } from "framer-motion";
import _ from "lodash";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
//components
//actions
//selector
//function
//constants
import { URL } from "constants/menu/url";
//styled
import { CsHeader, LayoutHeader, NamePage } from "../styled";

const { useBreakpoint } = Grid;

const LayoutMenu = () => {
    // -------------------------- VAR --------------------------
    const navigate = useNavigate();
    const { scrollY } = useScroll();
    const screens = useBreakpoint();
    const { pathname } = useLocation();
    // -------------------------- STATE ------------------------
    const [isshowbgHeader, setisshowbgHeader] = useState(false);

    // -------------------------- REDUX ------------------------
    // -------------------------- FUNCTION ---------------------
    const handleHome = () => {
        navigate("/");
    };
    const getPageName = () => {
        switch (pathname) {
            case URL.about: {
                return "About";
            }
            case URL.term: {
                return "Term of use";
            }
            default: {
                return null;
            }
        }
    };
    // -------------------------- EFFECT -----------------------
    useMotionValueEvent(scrollY, "change", (latest) => {
        if (latest > 10) {
            setisshowbgHeader(!false);
        } else {
            setisshowbgHeader(false);
        }
    });
    // -------------------------- RENDER -----------------------
    // -------------------------- MAIN -------------------------
    return (
        <LayoutHeader
            $isshowbgHeader={isshowbgHeader}
            $isHomePage={_.isEqual(pathname, "/")}
        >
            <Layout.Content className="content">
                <Flex justify="center" align="center">
                <CsHeader >
                    <Image
                        onClick={handleHome}
                        src="image/brand/logo.png"
                        alt="logo"
                        className="logo-img"
                        preview={false}
                    />
                    {!screens.xs && (
                        <Flex align="center" gap={16}>
                            <NamePage>{getPageName()}</NamePage>
                        </Flex>
                    )}
                </CsHeader>
                </Flex>
            </Layout.Content>
        </LayoutHeader>
    );
};
export default LayoutMenu;
