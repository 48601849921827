//node_modules
import { Flex, FloatButton, Layout, Tooltip } from "antd";
import { FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { Outlet, useLocation } from "react-router-dom";
import _ from "lodash";
//components
import LayoutFooter from "./component/layout_footer";
import LayoutMenu from "./component/layout_menu";
import LayoutMeta from "./component/layout_meta";
import { ContainerFloatButton } from "./styled";
//actions
//selector
//function
//constants
import { URL } from "constants/menu/url";
//styled

const LayoutRoot = () => {
    // -------------------------- VAR ---------------------------
    const { pathname } = useLocation();
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const handleOpen = (url: string) => {
        window.open(url);
    };

    const handleOpenMail = () => {
        window.open("mailto:hi@tonusd.org");
    };
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------

    return (
        <Layout>
            <LayoutMeta />
            <LayoutMenu />
            <Flex justify="center">
                <Layout.Content className="content">
                    <Outlet />
                </Layout.Content>
            </Flex>
            <LayoutFooter />
            {_.isEqual(pathname, URL.home) && (
                <ContainerFloatButton shape="circle">
                    <Tooltip placement="rightTop" title="Youtube">
                        <FloatButton icon={<FaYoutube />} onClick={() => handleOpen("https://www.youtube.com/@TonUSD_channel")} />
                    </Tooltip>
                    <Tooltip placement="rightTop" title="Twitter">
                        <FloatButton
                            icon={<FaXTwitter />}
                            onClick={() => handleOpen("https://X.com/TonUSD_X")}
                        />
                    </Tooltip>
                    <Tooltip placement="rightTop" title="Telegram Community">
                        <FloatButton
                            icon={<FaTelegramPlane />}
                            onClick={() =>
                                handleOpen("https://t.me/TonUSD_Chat")
                            }
                        />
                    </Tooltip>
                    <Tooltip placement="rightTop" title="Mail">
                        <FloatButton
                            icon={<IoMdMail />}
                            onClick={handleOpenMail}
                        />
                    </Tooltip>
                </ContainerFloatButton>
            )}
        </Layout>
    );
};

export default LayoutRoot;
