/* eslint-disable no-unused-vars */
//node_modules
import { Flex } from "antd";
import TermLeft from "./component/term_left";
import TermRight from "./component/term_right";
import { Container, ContainerTerm } from "./styled";
//components
import LayoutFooterMore from "layout/component/layout_footer_more";

//actions
//selector
//function
//constants
//styled

const TermScreen = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
       <Flex justify="center" vertical align="center">
            <ContainerTerm>
                <Container>
                    <TermLeft/>
                    <TermRight/>
                </Container>
            </ContainerTerm>
            <LayoutFooterMore />
       </Flex>
    );
};
export default TermScreen;