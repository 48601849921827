

export interface PageMeta  {
    title: string
    description?: string
    image?: string
}

interface PathList {
    paths: { [path: string]: { title: string; basePath?: boolean; description?: string } }
    defaultTitleSuffix: string
}

export const DEFAULT_META: PageMeta = {
    title: `TONUSD`,
    image: `${window.location.origin}/preview.png`,
    description: 'TonUSD is a pioneering cryptocurrency project aimed at creating a sustainable and reliable stablecoin on the TON (Telegram Open Network) blockchain platform. With a mission to provide an efficient, secure, and transparent payment solution, TonUSD aims to become a superior medium of exchange and store of value within the TON ecosystem as well as in the world of decentralized finance (DeFi).',
}

const getPathList = (): PathList => {
    return {
        paths: {
            "/term": { title: "Term of use" },
            "/about": { title: "About" },
        },
        defaultTitleSuffix: "TonUSD",
    }
}

export const getCustomMeta = (pathname:string) => {
    const pathList = getPathList()
    const pathMetadata = pathList.paths[pathname]
    if (pathMetadata) {
        return {
            title: `${pathMetadata.title} | ${pathList.defaultTitleSuffix}`,
            ...(pathMetadata.description && { description: pathMetadata.description }),
        }
    }        
    return null
}