//node_modules
//components
//actions
//selector
//function
//constants
//styled

import { ContainerRight } from "../styled";
import TermAmendments from "./component/term_amendments";
import TermDescription from "./component/term_description";
import TermDisclaimer from "./component/term_disclaimer";
import TermGeneralTerms from "./component/term_general_terms";
import TermProtection from "./component/term_protection";
import TermResponsibilities from "./component/term_responsibilities";

const TermRight = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerRight vertical id="containerId">
            <TermDescription />
            <TermGeneralTerms />
            <TermResponsibilities />
            <TermProtection />
            <TermDisclaimer />
            <TermAmendments />
        </ContainerRight>
    );
};
export default TermRight;
