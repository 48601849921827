//node_modules
//components
//actions
//selector
//function
//constants
//styled
import { ContainerAboutLeft, ContainerLeft, CsText } from "../styled";

const AboutLeft = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const scrollToId = (id: string) => {
        const element = document.getElementById(id);
        const container = document.getElementById("containerAbout");
        if( element && container) {
            const elementOffset = element.offsetTop;
            const containerScrollTop = container.scrollTop;
            window.scrollTo({ behavior: "smooth", top: containerScrollTop + elementOffset - 100})

        }
    };
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerAboutLeft>
            <ContainerLeft vertical>
                <CsText onClick={() => scrollToId("introducing")}>1. Introducing the TonUSD Foundation</CsText>
                <CsText onClick={() => scrollToId("foundation")}>2. The main goals of TonUSD Foundation include</CsText>
                <CsText onClick={() => scrollToId("operates")}>
                    3. TonUSD Foundation operates in the following main areas
                </CsText>
                <CsText onClick={() => scrollToId("vision")}>4. The vision of TonUSD Foundation</CsText>
            </ContainerLeft>
        </ContainerAboutLeft>
    );
};
export default AboutLeft;
