/* eslint-disable no-unused-vars */
import { MenuItemType, URL } from "./url"

export const items: (t:(key:string) => string) => MenuItemType[] = (t) => [
    {
        key: ["1"],
        icon: null,
        label: "TONUSD",
        url:URL.home
    },
]

