import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './type';

const initialState: InitialState = {
    highLight: ["1"],

}
export const navSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        setHighLight: (state, action) => {
            state.highLight = action.payload
        }
    },
});
const { actions } = navSlice;
export const { setHighLight } = actions;

export default navSlice.reducer
