import { createSlice } from '@reduxjs/toolkit';
import { InitialState } from './type';

const initialState: InitialState = {
    isCollapsed: false,
    accessToken: "",
    language: "fr"
}
export const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        updateCollapsed: (state, action) => {
            state.isCollapsed = action.payload
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
    },
});
const { actions } = globalSlice;
export const { updateCollapsed, setAccessToken, setLanguage } = actions;

export default globalSlice.reducer
