/* eslint-disable no-unused-vars */
//node_modules
import { Flex } from "antd";
//components
import LayoutFooterMore from "layout/component/layout_footer_more";
import AboutLeft from "./component/about_left";
import AboutRight from "./component/about_right";
//actions
//selector
//function
//constants
//styled
import { Container, ContainerAbout } from "./styled";

const AboutScreen = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex justify="center" vertical align="center">
            <ContainerAbout>
                <Container>
                    <AboutLeft />
                    <AboutRight />
                </Container>
            </ContainerAbout>

            <LayoutFooterMore />
        </Flex>
    );
};
export default AboutScreen;
