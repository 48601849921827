//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const AboutVision = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="vision">
            <Text className="fs-title bold">4. The vision of TonUSD Foundation</Text>
            <Text className="fs-content">
                The vision of TonUSD Foundation is to become a leading
                destination for financial services and products on the TON
                blockchain, and delivering real value and benefits to the user
                community worldwide.
            </Text>
            <Text className="fs-content">
                In summary, TonUSD Foundation is not just a non-profit
                organization, but also a place where the TonUSD user community
                can collaborate, share ideas, and contribute to the development
                of a decentralized and sustainable financial ecosystem on the
                TON blockchain.
            </Text>
        </Flex>
    );
};
export default AboutVision;
