import MetaTags from 'react-meta-tags';
import _ from "lodash"
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'stores';
import { useTranslation } from 'react-i18next';
//components
//actions
import { setHighLight } from 'stores/nav/slice';

//selector
//function
//constants
import { DEFAULT_META, getCustomMeta } from "constants/meta";
import { items } from "constants/menu";
import { MenuItemType } from 'constants/menu/url';

//styled

const LayoutMeta = () => {
    // -------------------------- VAR ---------------------------
    const { pathname } = useLocation()
    const { t } = useTranslation()
    // -------------------------- STATE -------------------------
    const [ selectedMenu, setSelectedMenu ] = useState<MenuItemType|null>(null)
    // -------------------------- REDUX -------------------------
    const dispatch = useAppDispatch()
    // -------------------------- FUNCTION ----------------------
    const pageMeta = getCustomMeta(pathname) || {}
    const { title: pageTitle, description, image } = { ...DEFAULT_META, ...pageMeta }

    // -------------------------- EFFECT ------------------------
    const menuItems = useMemo(() => {
        return items(t)
      }, [t])
    useEffect(() => {
        for(let i = 0; i < menuItems?.length; i++ ) {
            if(menuItems[i]?.children) {
                const children = menuItems[i]?.children ?? []
                if(children?.length) {
                    const findItem = children?.find((dt) => _.isEqual(pathname, dt?.url))
                    if(findItem) {
                        setSelectedMenu(findItem)
                    } else {
                        setSelectedMenu(null)
                    }
                }
            } else {
                const findItem = menuItems?.find((dt) => _.isEqual(pathname, dt?.url))
                if(findItem) {
                    setSelectedMenu(findItem)
                }
            }
        }
    },[menuItems, pathname])
    
    useEffect(() => {
        dispatch(setHighLight(selectedMenu?.key ?? []))
    },[dispatch, selectedMenu?.key])
    // -------------------------- RENDER ------------------------
    const title = useMemo(() => {
        return selectedMenu?.label || pageTitle
    },[pageTitle, selectedMenu?.label])
    // -------------------------- MAIN --------------------------
    return (
        <MetaTags>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image} />
        </MetaTags>
    )
}
export default LayoutMeta



    
    