import { Flex, FloatButton, Layout, Row, Typography } from "antd";
import { HEADER_HEIGHT } from "constants/index";
import styled, { css } from "styled-components";


export const ContainerLogo = styled(Row)`
    height: 100%;
    width: 200px;
`

export const Mask = styled(Row) <{ isTop: boolean }>`
    background: ${(props) => props.theme.component.Layout.siderBg};
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    ${(props) =>
        props.isTop
            ? css`
                  border-bottom-right-radius: 90px;
              `
            : css`
                    border-top-right-radius: 90px;
              `}
    
`
export const ScMenuItem = styled(Flex)`
    width: 80px;
    justify-content: center;
    > svg {
        font-size: ${(props) => props.theme.component.Menu.iconSize}px;
    }
`
export const LayoutHeader = styled(Layout.Header) <{ $isshowbgHeader: boolean, $isHomePage?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${HEADER_HEIGHT}px;
    position: fixed;
    width: 100vw;
    z-index: 10;
    ${(props) =>
        props.$isHomePage &&
            css`
                background: transparent;
            `
    }
    transition: background-color 0.5s ease;
    padding: 0px;

    @media screen and (max-width: 768px) {
        padding: 0px 12px;
    }
    @media screen and (max-width: 600px) {
        padding: 0px 8px;
        ${(props) =>
            props.$isshowbgHeader ?
                css`
                    background: ${props.theme.component.Layout.headerBg};

                `
            :
                css`
                    background: transparent;
                `
        }
    }
    ${(props) =>
            props.$isHomePage ?
                css`
                    border-bottom: none;
                `
            :
                css`
                    border-bottom: 1px solid #B0B0B0;
                `
        }
    
`

export const ContainerChain = styled(Flex)`
    max-width: 152px;
    width: auto;
    height: 52px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    border: 3px solid ${(props) => props.theme.token.colorBorderSecondary};
    background: ${(props) => props.theme.token.colorBgLayout};
`

export const ContainerConnectWallet = styled.div`
    > div > div > tc-root > button {
        height: 52px;
        color: ${(props) => props?.theme.token.colorWhite};
        background: ${(props) => props?.theme.token.colorBgBase};
        border-radius: 12px;
    }
    > div > div > tc-root > div > button {
        height: 52px;
        color: ${(props) => props?.theme.token.colorWhite};
        background: ${(props) => props?.theme.token.colorBgBase};
        border-radius: 12px;
        > svg {
            fill: white;
        }
    }
`

export const CsLayoutFooter = styled(Layout.Footer)`
    max-width: 1320px;
    width: 100%;
    padding: 24px 24px;
    @media screen and (max-width: 600px) {
        padding: 24px 16px;
    }

`

export const TextWhite = styled(Typography.Text)<{$isActive:boolean}>`
    color: ${(props) => props?.theme.token.colorWhite} !important;
    cursor: pointer;
    ${(props) =>
        props.$isActive ?
            css`
                opacity: 1;
              `
            :
            css`
                opacity: 0.7;
              `
        }
`

export const ContainerFooter = styled(Flex)`
    background: ${(props) => props.theme.component.Layout.footerBg};
    border-top: 1px solid ${(props) => props.theme.component.Divider.colorSplit};
    z-index:1;
`

export const ContainerFloatButton = styled(FloatButton.Group)`
    left: 24px;
    bottom: 62px;
    @media screen and (max-width: 600px) {
        left: calc(100vw - 56px);
    }
`

export const TextSmall = styled(Typography.Text)`
    color: #9d9d9d;
    font-size: 14px;
`

export const CsSpan = styled.span`
    color: ${(props) => props?.theme.token.colorWhite};
    cursor: pointer;
`

export const NamePage = styled(Typography.Text)`
    color: ${(props) => props?.theme.token.colorWhite};
    font-size: 32px;
    font-weight:600;
`

export const ContainerMore = styled(Flex)<{isNoStyle?:boolean}>`
    padding-bottom: ${({isNoStyle}) => isNoStyle ? "0px" : "48px"};
    @media screen and (min-width: 769px) {
        display: none;
    }
`

export const CsHeader = styled(Flex)`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    max-width: 1320px;
    padding: 0px 24px 0px 24px;
    @media screen and (max-width: 768px) {
        padding: 0px 0px 0px 0px;
    }
    @media screen and (max-width: 600px) {
        padding: 0px 4px 0px 8px;
    }
`