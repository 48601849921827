/* eslint-disable no-unused-vars */
//node_modules
import { Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled
import { ContainerLeft, ContainerTermLeft, CsText } from "../styled";

const { Text } = Typography;

const TermLeft = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const scrollToId = (id: string) => {
        const element = document.getElementById(id);
        const container = document.getElementById("containerId");
        if( element && container) {
            const elementOffset = element.offsetTop;
            const containerScrollTop = container.scrollTop;
            window.scrollTo({ behavior: "smooth", top: containerScrollTop + elementOffset - 100})

        }
    };
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerTermLeft>
            <ContainerLeft vertical>
                <CsText onClick={() => scrollToId("general")}>
                    1. General Terms
                </CsText>
                <CsText onClick={() => scrollToId("responsibilities")}>
                    2. Rights and Responsibilities
                </CsText>
                <CsText onClick={() => scrollToId("protection")}>
                    3. Data Protection
                </CsText>
                <CsText onClick={() => scrollToId("disclaimer")}>
                    4. Disclaimer of Liability
                </CsText>
                <CsText onClick={() => scrollToId("amendments")}>
                    5. Amendments and Termination
                </CsText>
            </ContainerLeft>
        </ContainerTermLeft>
    );
};
export default TermLeft;
