import { Route, Routes } from "react-router-dom";

//components
import LayoutRoot from "layout/layoyt_root"
import ComingSoonScreen from "screen/00_Coming_Soon/coming_soon_screen";
import TermScreen from "screen/01_Term/term_screen"
import AboutScreen from "screen/02_About/about_screen"
//actions
//selector
//function
//constants
import { URL } from "constants/menu/url";
//styled

const App = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Routes>
            <Route element={<LayoutRoot />}>
                <Route path={URL.home} element={<ComingSoonScreen />} />
                <Route path={URL.term} element={<TermScreen />} />
                <Route path={URL.about} element={<AboutScreen />} />
                <Route path="*" element={<ComingSoonScreen />} />
            </Route>
        </Routes>
    );
};

export default App;
