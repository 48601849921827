//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermResponsibilities = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="responsibilities">
            <Text className="fs-title bold">2. Rights and Responsibilities:</Text>
            <Text className="fs-content" >
                2.1 You are responsible for protecting your personal
                information and wallet passwords and not sharing them with
                anyone else. By keeping your password information secure and not
                sharing it with anyone else, you will protect your account and
                personal information from unauthorized access and misuse. This
                is important to ensure the security and safety of both users and
                the TonUSD system. and ensures a safe and transparent operating
                environment.
            </Text>
            <Text className="fs-content" >
                2.2 TonUSD is not responsible for any damages arising from
                improper or incorrect use of the service.
            </Text>
        </Flex>
    );
};
export default TermResponsibilities;
