//node_modules
//components
import AboutFoundation from "./component/about_foundation";
import AboutIntroducing from "./component/about_introducing";
import AboutOperates from "./component/about_operates";
import AboutVision from "./component/about_vision";
//actions
//selector
//function
//constants
//styled

import { ContainerRight } from "../styled";

const AboutRight = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerRight vertical id="containerAbout">
            <AboutIntroducing />
            <AboutFoundation />
            <AboutOperates />
            <AboutVision />
        </ContainerRight>
    );
};
export default AboutRight;
