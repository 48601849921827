import { ConfigProvider } from "antd";
import configAxios from "api/config";
import LanguageProvider from "context/language";
import React from "react";
import { Provider } from "react-redux";
import store from "stores";
import { ThemeProvider } from "styled-components";
import { theme } from "theme";

const GlobalHooks = ({ children }: { children: React.ReactElement | null }) => {
    configAxios();
    return children;
};

const Providers = ({ children }: { children: React.ReactNode }) => {
    return (
        <Provider store={store}>
            <GlobalHooks>
                <ConfigProvider theme={theme}>
                    <ThemeProvider
                        theme={{
                            token: theme?.token,
                            component: theme.components,
                        }}
                    >
                        <LanguageProvider>
                            {children}
                        </LanguageProvider>
                    </ThemeProvider>
                </ConfigProvider>
            </GlobalHooks>
        </Provider>
    );
};

export default Providers;
