//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const AboutIntroducing = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="introducing">
            <Text className="fs-title bold">
                1. Introducing the TonUSD Foundation
            </Text>
            <Text className="fs-content">
                TonUSD Foundation is a non-profit organization with the
                primary goal of creating and managing the ecosystem of TonUSD, a
                stable crypto currency operating on the TON blockchain. This
                organization is committed to building a decentralized and
                transparent financial ecosystem, providing advanced and
                integrated financial services and products to the user
                community.
            </Text>
        </Flex>
    );
};
export default AboutIntroducing;
