import { Flex, Image, Row, Space, Typography } from "antd";
import styled from "styled-components";


export const Background = styled(Row)`
    width: 100vw;
    height: 100vh;
    background-image: url('../image/background/Background.png');
    background-size: cover;
    background-position: 100%;
`
export const Container = styled(Space)`
    width: 60%; 
    height: 40%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    

    @media screen and (max-width: 769px){
        width: 90% !important; 
        height: 20% !important;
        margin: auto;
    }
`
export const ContainerContent = styled(Flex)`
    margin: auto;
`
export const TitleComingSoon = styled(Typography.Title)`
    color: white !important; 
    font-size: 60px !important; 
    text-transform: uppercase;
    margin: 0 !important; 
    @media screen and (max-width: 576px) {
        font-size: 40px !important;
    }
`
export const ImageComingSoon = styled(Image)`
     margin-bottom: 0px; 
     width: 72px !important;
`
export const DescriptionComingSoon = styled(Typography.Text)`
    font-size: 30px;
    color: white;
    width: 55%;
    text-align: center;
    @media screen and (max-width: 1025px){
        width: 70% !important;
     }
    @media screen and (max-width: 769px){
        width: 55% !important;
        font-size: 20px;
     }
    @media screen and (max-width: 426px){
        width: 80% !important;
        font-size: 20px;
     }
     @media screen and (max-width: 376px) {
        width: 80% !important;
     }
`
export const ContainerCountDown = styled(Flex)`
    width: 100%;
    background: rgba(255, 255, 255, 0.07);
    padding: 0px 15px 5px 15px;
    border-radius: 15px; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`
export const CountDownText = styled(Typography.Text)`
    font-size: 80px;
    font-weight: bold;
    color: white;
    @media screen and (max-width: 576px){
        font-size: 32px !important;
     }
    
`
export const CountDownTextDescription = styled(Typography.Text)`
    font-weight: bold;
    color: white;
    @media screen and (max-width: 426px){
        font-size: 10px !important;
     }
` 