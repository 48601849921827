//node_modules
import { Button, Flex, Grid } from "antd";
//components
import { TelegramChanelIcon, WhitePaperIcon, WhitePaperViIcon } from "components/svg";
//actions
//selector
//function
//constants
//styled
import { Background, Container, ContainerContent, DescriptionComingSoon, ImageComingSoon, TitleComingSoon } from "./styled";

const { useBreakpoint } = Grid

const ComingSoonScreen = () => {
    // -------------------------- VAR ---------------------------
    const screens = useBreakpoint()
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const handleClick = (url:string) => {
        window.open(url)
    }
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Background>
            <Container>
                <ContainerContent gap={12} align="center" justify="start" vertical>
                    <ImageComingSoon
                        src="/logo.png"
                        alt="logo"
                        preview={false}
                    />
                    <TitleComingSoon >coming soon</TitleComingSoon>
                    <DescriptionComingSoon >A UNIQUE DIGITAL ASSET BACKED BY THE POWER OF LIQUIDITY PROVIDER</DescriptionComingSoon>
                    <Flex justify="space-around" vertical={screens.xs ? !false : false} gap={24} style={{width:"100%", height:"auto", marginTop:24}}>
                        <Button 
                            type="text" style={{ height: "auto"}}
                            onClick={() => handleClick("https://tonusd.org/white_paper_en.pdf")}
                        >
                            <WhitePaperIcon width={screens.xs ? "250px" : "300px"}/>
                        </Button>
                        <Button 
                            type="text" style={{ height: "auto"}}
                            onClick={() => handleClick("https://tonusd.org/white_paper_vi.pdf")}
                        >
                            <WhitePaperViIcon width={screens.xs ? "250px" : "300px"}/>
                        </Button>
                        <Button 
                            type="text" 
                            onClick={() => handleClick("https://t.me/TonUSD_channel")}
                            style={{ height: "auto"}}
                        >
                            <TelegramChanelIcon width={screens.xs ? "250px" : "300px"}/>
                        </Button>
                    </Flex>
                </ContainerContent>
            </Container>
        </Background>
    );
};
export default ComingSoonScreen;
