//node_modules
import { ReactNode } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";

//components
//actions
//selector
import { useGobalSelector } from "stores/global/selector";
//function
//constants
import enTranslation from "locales/en.json";
import frTranslation from "locales/fr.json";
//styled

const LanguageProvider = ({ children }: {children: ReactNode}) => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    const dataGolbal = useGobalSelector()
    const { language } = dataGolbal
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // eslint-disable-next-line import/no-named-as-default-member
    i18n.init({
        interpolation: { escapeValue: false },
        lng: language,
        fallbackLng: 'en',
        resources: {
            en: { translation: enTranslation },
            fr: { translation: frTranslation },
        },
    });
    // -------------------------- MAIN --------------------------
    return (
        <I18nextProvider i18n={i18n}>
            {children}
        </I18nextProvider>
       
    );
};
export default LanguageProvider;

