import React from "react"

export const URL = {
    home: "/",
    term: "/term",
    about: "/about",
    news: "https://news.tonusd.org/"
}

export interface MenuItemType {
    key?: string[],
    icon: React.ReactNode,
    label: string,
    url?: string,
    isExternal?:boolean,
    children?: {
        key: string[],
        icon: React.ReactNode,
        label: string,
        url: string,
        isExternal?:boolean,
    }[]
}