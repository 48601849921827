//node_modules
import { Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
//components
//actions
//selector
//function
//constants
import { URL } from "constants/menu/url";
//styled
import { ContainerMore, TextWhite } from "layout/styled";

const LayoutFooterMore = ({isNoStyle}:{isNoStyle?:boolean}) => {
    // -------------------------- VAR ---------------------------
    const navigate = useNavigate();
    const { pathname } = useLocation();
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const handleClick = (url: string, isExternal = false) => {
        if (isExternal) {
            window.open(url);
        } else {
            navigate(url);
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerMore justify="center" isNoStyle={isNoStyle}>
            <Space direction="horizontal">
                <TextWhite
                    onClick={() => handleClick(URL.about)}
                    $isActive={_.isEqual(pathname, URL.about)}
                >
                    About
                </TextWhite>
                <TextWhite $isActive={!false}>|</TextWhite>
                <TextWhite
                    $isActive={false}
                    onClick={() => handleClick(URL.news, !false)}
                >
                    News
                </TextWhite>
                <TextWhite $isActive={!false}>|</TextWhite>
                <TextWhite
                    onClick={() => handleClick(URL.term)}
                    $isActive={_.isEqual(pathname, URL.term)}
                >
                    Term of use
                </TextWhite>
            </Space>
        </ContainerMore>
    );
};
export default LayoutFooterMore;
