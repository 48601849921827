import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <Svg viewBox="0 0 20 20"  fill="none" {...props}>
            <path
                d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                stroke="currentColor"
            ></path>
            <path
                d="M13.4145 5.58131H6.5853C5.32964 5.58131 4.5338 6.93579 5.16549 8.03073L9.38021 15.336C9.65523 15.813 10.3445 15.813 10.6195 15.336L14.8351 8.03073C15.4659 6.93751 14.6701 5.58131 13.4153 5.58131H13.4145ZM9.37677 13.1453L8.45889 11.3688L6.2441 7.40763C6.09799 7.15409 6.27847 6.82922 6.58444 6.82922H9.37592V13.1461L9.37677 13.1453ZM13.7539 7.40677L11.54 11.3697L10.6221 13.1453V6.82836H13.4136C13.7196 6.82836 13.9 7.15323 13.7539 7.40677Z"
                fill="currentColor"
            ></path>
        </Svg>
    );
};

export default Icon;
