import type { ThemeConfig } from "antd";

export const theme:ThemeConfig = {
    token: {
        colorBgLayout: "#262626",
        colorSplit: "#222222",
        colorPrimary: "#8B56FF",
        colorTextDescription: "#A4A8AB",
        boxShadowSecondary: "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
        colorBorderSecondary: "#f78e01",
        colorBgBase: "#355dff",
        colorWhite: "white"
    },
    components: {
        Layout: {
            siderBg: "#222222",
            colorBorder: "#8B56FF",
            headerBg: "#222222",
            footerBg: "#222222",
        },
        Menu: {
            colorBgContainer: "transparent",
            colorBgTextHover: "transparent",
            colorText: "white",
            itemHoverColor: "#FFC120",
            itemSelectedColor: "#FFC120",
            colorPrimary: "#FFC120",
            itemHeight: 48,
            fontSize: 16,
            subMenuItemBg: "transparent",
            iconSize: 16,
            activeBarBorderWidth:0,
        },
        Typography: {
            fontSize: 16,
            colorTextDescription: "#A4A8AB",
            colorText: "white",
            colorTextHeading: "#cd9f7a"
        },
        Drawer: {
            colorBgElevated: "#F4F5FA",
            colorIcon: "black",
            paddingLG: 16
        },
        Button: {
            colorPrimary: "#E6A101",
            colorPrimaryHover: "#E6A101"
        },
        Input: {
            controlHeightLG:48,
        },
        Segmented: {
            itemSelectedBg: "#0E4152",
            itemSelectedColor: "white",
            trackPadding: 4,
            controlHeightLG: 48
        },
        DatePicker: {
            controlHeightLG	: 48
        },
        Select: {
            controlHeightLG	: 48
        },
        Checkbox: {
            colorPrimary: "#E6A101",
            colorPrimaryHover: "#E6A101"
        },
        FloatButton: {
            colorPrimary: "white",
            colorFillContent: '#fff',
            colorBgElevated: '#fff'
            
        },
        Dropdown: {
            controlItemBgActive: "rgba(0, 0, 0, 0.04)",
            controlItemBgActiveHover: "rgba(0, 0, 0, 0.04)",
        },
        Divider: {
            colorSplit: "#393939"
        }
    }
}

