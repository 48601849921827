//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermDisclaimer = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="disclaimer">
            <Text className="fs-title bold">4. Disclaimer of Liability:</Text>
            <Text className="fs-content">
                4.1 You acknowledge that the use of TonUSD is on an{" "}
                {`"as is" and
                "as available"`}{" "}
                basis, and there are no guarantees of stability or accuracy of
                the service.
            </Text>
            <Text className="fs-content">
                This means that you understand and accept that there may
                be instability or inaccuracy in the TonUSD service, and you are
                responsible for the risks when using the service.
            </Text>
            <Text className="fs-content">
                4.2 TonUSD is not responsible for any damages arising from
                the use or inability to use the service. This means that you
                understand and accept that TonUSD is not liable for any loss or
                damage, including direct, indirect, incidental, or consequential
                damages arising from the use or inability to use the TonUSD
                service. You are fully responsible for your decisions and
                actions when using the service.
            </Text>
        </Flex>
    );
};
export default TermDisclaimer;
