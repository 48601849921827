//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const AboutOperates = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="operates">
            <Text className="fs-title bold">
                3. TonUSD Foundation operates in the following main areas:
            </Text>
            <Text className="fs-content">
                Product Development: The organization initiates and sponsors the
                development of products and services related to the TonUSD
                currency, including payment applications, exchanges,
                decentralized borrowing and lending (DeFi) services.
            </Text>
            <Text className="fs-content">
                Community Support: Providing support and education to the user
                community, helping them understand better the features and
                potential and how to use TonUSD.
            </Text>
            <Text className="fs-content">
                Event Organization and Conferences: Hosting events and
                conferences to promote awareness and understanding of TonUSD
                within the financial community.
            </Text>
        </Flex>
    );
};
export default AboutOperates;
