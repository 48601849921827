//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const AboutFoundation = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="foundation">
            <Text className="fs-title bold">
                2. The main goals of TonUSD Foundation include:
            </Text>
            <Text className="fs-content">
                Creating a stable and secure currency: This helps improve
                liquidity and stability in financial transactions on the TON
                blockchain platform.
            </Text>
            <Text className="fs-content">
                Building a decentralized and flexible financial ecosystem:
                The goal is to enable everyone to access and use financial
                services easily, without relying on traditional intermediary
                organizations.
            </Text>
            <Text className="fs-content">
                Developing and expanding the TonUSD ecosystem:
                Collaborating and partnering with interested organizations and
                individuals to expand and develop the TonUSD ecosystem. It can
                be said that TonUSD is not another stablecoin; {`it's`} a unique
                digital asset backed by the power of liquidity providers.
            </Text>
        </Flex>
    );
};
export default AboutFoundation;
