//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermDescription = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical>
            <Text>
            THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR RIGHTS AND OBLIGATIONS, AS WELL AS TERMS, LIMITATIONS, AND DISCLAIMERS. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING TONUSD.BY ACCEPTING THESE TERMS, YOU AGREE AND ACKNOWLEDGE THAT:
            </Text>
        </Flex>
    );
};
export default TermDescription;
