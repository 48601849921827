//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermAmendments = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="amendments">
            <Text className="fs-title bold">5. Amendments and Termination:</Text>
            <Text className="fs-content">
                5.1 TonUSD reserves the right to adjust or terminate this
                agreement without prior notice to users. This provides TonUSD
                with flexibility in adjusting the terms and conditions of the
                service according to demand and market conditions without the
                consent of users.
            </Text>
            <Text className="fs-content">
                5.2 Your continued use of TonUSD after changes will be
                considered as your acceptance of those changes. This means that
                if you continue to use the TonUSD service after changes in this
                agreement, you will be considered to have accepted those changes
                and are willing to comply with the new terms.
            </Text>
        </Flex>
    );
};
export default TermAmendments;
