//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermProtection = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="protection">
            <Text className="fs-title bold">3. Data Protection:</Text>
            <Text className="fs-content" >
                3.1 TonUSD complies with data protection regulations
                regarding your personal information and only uses your personal
                information for the right purposes.
            </Text>
            <Text className="fs-content" >
                3.2 TonUSD may collect, store, and use your personal
                information according to the TonUSD privacy policy. This ensures
                that you understand and agree that your personal information may
                be used to provide services, enhance the TonUSD user experience,
                and will be handled according to security regulations and
                standards.
            </Text>
        </Flex>
    );
};
export default TermProtection;
