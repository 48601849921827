//node_modules
import { Flex, Typography } from "antd";
//components
//actions
//selector
//function
//constants
//styled

const { Text } = Typography;

const TermGeneralTerms = () => {
    // -------------------------- VAR ---------------------------
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <Flex vertical gap={16} id="general">
            <Text className="fs-title bold">1. General Terms:</Text>
            <Text className="fs-content">
                1.1 The following terms and conditions {`("Terms of Use")`}{" "}
                govern your use of TonUSD. These Terms of Use also apply to your
                purchase and sale of products and services using TonUSD.
            </Text>
            <Flex vertical className="ml-32">
                <Text className="fs-content">
                    • You have read, understood, and agreed to be bound by these
                    Terms of Use;
                </Text>
                <Text className="fs-content">• You assume all the obligations set forth herein;</Text>
                <Text className="fs-content">
                    • You are of sufficient legal age and capacity to use the
                    TonUSD;
                </Text>
                <Text className="fs-content">
                    • You are not under the control of jurisdiction that
                    explicitly prohibits the use of TonUSD;
                </Text>
                <Text className="fs-content">
                    • You use the TonUSD at your discretion and under your own
                    responsibility.
                </Text>
            </Flex>
            <Text className="fs-content">
                These Terms of Use constitute a legally binding agreement
                between You and TonUSD regarding your access to and use of
                TonUSD. By accessing or using TonUSD, you agree to be bound by
                these Terms of Use. If you do not wish to be bound by these
                Terms of Use, please do not access or use TonUSD.
            </Text>
            <Text className="fs-content">
                1.2 TonUSD is not responsible for any damages arising from
                improper or incorrect use of the service.
            </Text>
        </Flex>
    );
};
export default TermGeneralTerms;
