//node_modules
import { Divider, Flex, Grid, Space, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash"
//components
import { TonIcon } from "components/svg";
import LayoutFooterMore from "./layout_footer_more";

//actions
//selector
//function
//constants
import { URL } from "constants/menu/url";
//styled
import {
    ContainerFooter,
    CsLayoutFooter,
    CsSpan,
    TextSmall,
    TextWhite,
} from "layout/styled";

const { useBreakpoint } = Grid;
const { Text } = Typography;

const LayoutFooter = () => {
    // -------------------------- VAR ---------------------------
    const screens = useBreakpoint();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    // -------------------------- STATE -------------------------
    // -------------------------- REDUX -------------------------
    // -------------------------- FUNCTION ----------------------
    const handleClick = (url: string, isExternal = false) => {
        if (isExternal) {
            window.open(url);
        } else {
            navigate(url);
        }
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    // -------------------------- EFFECT ------------------------
    // -------------------------- RENDER ------------------------
    // -------------------------- MAIN --------------------------
    return (
        <ContainerFooter justify="center">
            <CsLayoutFooter>
                <Flex
                    justify="space-between"
                    gap={16}
                    align={screens?.xs ? "center" : "center"}
                    vertical={screens?.xs ? true : false}
                    style={{ marginTop: "12px", marginBottom: screens?.xs ? "24px" : "0px" }}
                >
                    <TextWhite $isActive={!false}>© TONUSD. All Right Reserved 2024.</TextWhite>
                    { ( screens.xxl || screens.xl || screens.lg || screens.md ) && (
                            <Space
                                direction={
                                    screens.xs ? "vertical" : "horizontal"
                                }
                            >
                                <TextWhite
                                    onClick={() => handleClick(URL.about)}
                                    $isActive={_.isEqual(pathname, URL.about)}
                                >
                                    About
                                </TextWhite>
                                <TextWhite $isActive>|</TextWhite>
                                <TextWhite
                                    onClick={() =>
                                        handleClick(URL.news, !false)
                                    }
                                    $isActive={false}
                                >
                                    News
                                </TextWhite>
                                <TextWhite $isActive>|</TextWhite>
                                <TextWhite
                                    onClick={() => handleClick(URL.term)}
                                    $isActive={_.isEqual(pathname, URL.term)}

                                >
                                    Term of use
                                </TextWhite>
                            </Space>
                        )}
                </Flex>
                {  ( screens.xs && _.isEqual(pathname, URL.home) ) &&
                    <LayoutFooterMore isNoStyle />
                }
                <Divider />
                <Flex justify={ ( screens.md || screens.xs ) ? "flex-start" : "center"}>
                    <Space>
                        <Text style={{ color: "#9d9d9d", fontSize:"20px" }}>Based on</Text>
                        <TonIcon width={20} color="white" />
                        <Text style={{ color: "#9d9d9d", fontSize:"20px" }}>TON</Text>
                    </Space>
                </Flex>
                <Flex style={{ marginTop: "24px" }}>
                    <TextSmall>
                        Disclaimer: Nothing on this site is investment advice.
                        All information is for informational purposes only. You
                        should not construe any such information or other
                        material as legal, tax, investment, financial, or other
                        advice. Nothing contained on our site constitutes a
                        solicitation, recommendation, endorsement, or offer by
                        TONUSD or any third party service provider to buy or
                        sell any assets, digital coins and tokens, securities or
                        other financial instruments in this or in in any other
                        jurisdiction in which such solicitation or offer would
                        be unlawful under the securities laws of such
                        jurisdiction. Please view{" "}
                        <CsSpan onClick={() => handleClick(URL.term)}>
                            Terms of use
                        </CsSpan>{" "}
                        for more information.
                    </TextSmall>
                </Flex>
            </CsLayoutFooter>
        </ContainerFooter>
    );
};
export default LayoutFooter;
