import { Flex, Typography } from "antd";
import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex-direction: row;
    @media screen and (max-width: 768px) {
        padding: 24px 12px 24px 12px;
        margin-top: 0px;
    }
`

export const ContainerContent = styled(Flex)` 
    width: 100%;
    max-width: 996px;
    margin-top: 16px;
`

export const ContainerLeft = styled(Flex)`
    width: 316px;
    padding: 32px 24px 32px 0px;
    height: 100%;
    gap: 16px;
    position: fixed;
    @media screen and (max-width: 1280px) and (min-width: 768px) { 
        left: 16px;
    }
    @media screen and (max-width: 768px) {
        display: none;
        position: static;
        padding: 32px 0px 48px 0px;
        width: 0px;
    }
`

export const TextUnderline = styled(Typography.Text)`
    padding: 5px 0px;
    border-bottom:1px solid white;
`
export const ContainerRight = styled(Flex)`
    width: calc(100% - 316px);
    padding: 24px;
    gap: 36px;
    height: 100%;
    min-height: 100vh;
    border-left: 1px solid #B0B0B0;
    @media screen and (max-width: 768px) {
        height: 100%;
        overflow-y: auto;
        padding: 4px;
        border-left: none;
        width: 100%;
    }
`

export const ContainerTerm = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-width: 1320px;
    margin-top: 90px;
`

export const ContainerTermLeft = styled.div`
    width: 316px;
    height: 100%;
    @media screen and (max-width:768px) {
        width: 0px;
    }
`

export const CsText = styled(Typography.Text)`
    cursor: pointer;
    &:hover {
        text-decoration: underline
    }
`